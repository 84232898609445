import React from "react"
import { Link } from "gatsby"

import { withPodcastDetails } from "./withPodcastDetails"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"
import { renderCode } from "../Wrappers/Sanity/RichText"

import { RichText } from "../RichText/RichText"
import { PodcastCard } from "./PodcastCard"
import { CardBlockquote } from "../Cards/CardBlockquote"
import { Grid } from "../Grid/Grid"
import { Hero } from "../Section/Hero/Hero"
import { Icon } from "../Icon/Icon"
import {
  Container,
  Responsive,
  GridColumn,
  Card,
  Image,
  DateTime,
  Title,
  Subtitle,
  Sponsor,
  Location,
  LocationText,
  CTABar,
  Button,
  Row,
  Column,
  BackLink,
  BackLinkText,
  Actions,
  WishlistButton,
  MobileWishlistButton,
} from "./PodcastDetails.styled"

export const PodcastDetails = withPodcastDetails(
  ({
    podcast: { additionalContent, content, host, image, location, sponsor, url, embed, title, attributes },
    podcasts,
    video,
    handlePlay = () => false,
    template: { about, aboutHost, back, listen, relatedAll, relatedTitle, showRelated, sponsor: sponsoredBy },
    handleSavedItem,
    existsInSavedItems,
    isLoggedIn,
  }: Props): JSX.Element => (
    <>
      <Responsive screen={`md>`}>
        <Hero colour="secondary" size={`small`} extended backLink={"/podcasts"} />
      </Responsive>
      <Responsive screen={`<md`}>
        <BackLink as={Link} title={back} theme={`tertiary`} to={routeResolver({ type: `podcast` })}>
          <Icon name={`chevronLeft`} size={`s`} colour={`tertiary`} />
          <BackLinkText>{back}</BackLinkText>
        </BackLink>
      </Responsive>
      <Container collapse={`<md`} position={`relative`} width={`xl`} spacing={`md`}>
        <Card size={`full`}>
          <Row wrap>
            <Column width={`md:w-1/2`}>
              {image && <Image alt={image.alt || ""} image={getFluidGatsbyImage(image, { with: 800, height: 400, cropFocus: "NORTH" })} />}
            </Column>
            <Column width={`md:w-1/2`}>
              <Row>
                <Column width={`md:w-1/2`}>
                  {attributes?.publishedAt && (
                    <DateTime>
                      {new Intl.DateTimeFormat(`en-AU`, {
                        day: `2-digit`,
                        month: `long`,
                        year: `numeric`,
                        timeZone: `Australia/Melbourne`,
                      }).format(Date.parse(`${new Date(attributes?.publishedAt)}`))}
                    </DateTime>
                  )}
                </Column>
                <Column>
                  {isLoggedIn && (
                    <Responsive screen={`md>`}>
                      <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </WishlistButton>
                    </Responsive>
                  )}
                  {isLoggedIn && (
                    <Responsive screen={`<md`}>
                      <MobileWishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </MobileWishlistButton>
                    </Responsive>
                  )}
                </Column>
              </Row>
              <Title>{title}</Title>
              <Responsive screen={`md>`}>
                {location && (
                  <Location>
                    <Icon name={`pin`} size={`m`} colour={`grey-light`} />
                    <LocationText>{location}</LocationText>
                  </Location>
                )}
                <br />
                <Actions>
                  {url && (
                    <Button size={`large`} theme={`primary`} colour={`dark`} as={`a`} target={`_blank`} href={url}>
                      {listen}
                    </Button>
                  )}
                  {video ? (
                    <Button size={`large`} theme={`primary`} colour={`dark`} title={"Watch Video"} as={null} onClick={() => handlePlay(video)}>
                      Watch Video
                    </Button>
                  ) : null}
                </Actions>
              </Responsive>
            </Column>
          </Row>
          {(content || additionalContent) && (
            <Row wrap>
              <Column width={`md:w-1/2`}>
                <Responsive screen={`md>`}>
                  <Subtitle>{about}</Subtitle>
                </Responsive>
                {content && <RichText>{content}</RichText>}
              </Column>
              <Column width={`md:w-1/2`}>
                {embed?.code && <>{renderCode(embed?.code)}</>}
                {additionalContent && <RichText>{additionalContent}</RichText>}
              </Column>
            </Row>
          )}
        </Card>
      </Container>

      {host?.name && host?.image && host?.biography && (
        <Container condense={`<md`} spacing={`md`} topSpacing={`md`} width={`xl`}>
          <CardBlockquote image={getFluidGatsbyImage(host?.image, { maxWidth: 800 })} title={aboutHost?.replace(`{name}`, host?.name)}>
            <RichText>{host?.biography}</RichText>
          </CardBlockquote>
        </Container>
      )}

      {sponsor && (
        <Responsive screen={`<md`}>
          <Container spacing={`md`}>
            <Subtitle>
              {sponsoredBy}&nbsp;<Sponsor>{sponsor}</Sponsor>
            </Subtitle>
          </Container>
        </Responsive>
      )}

      {showRelated && podcasts?.length > 0 && (
        <Container collapse={`<md`} spacing={`md`} topSpacing={`md`} width={`xl`}>
          <Grid show={3} title={relatedTitle} viewMoreLink={routeResolver({ type: `podcasts` })} viewMoreButton={relatedAll} viewMoreArrow>
            {podcasts?.map(({ date, id, image, thumbnail, location, summary, title, attributes, ...item }) => (
              <GridColumn key={id}>
                <PodcastCard
                  image={image}
                  thumbnail={thumbnail}
                  title={title}
                  link={routeResolver({ item })}
                  date={attributes?.publishedAt && new Date(attributes.publishedAt)}
                />
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}

      {sponsor && (
        <Responsive screen={`md>`}>
          <Container spacing={`md`} width={`xl`}>
            <Subtitle>
              {sponsoredBy}&nbsp;<Sponsor>{sponsor}</Sponsor>
            </Subtitle>
          </Container>
        </Responsive>
      )}

      {url || video ? (
        <CTABar>
          <Actions>
            {url && (
              <Button size={`medium`} theme={`primary`} colour={`dark`} as={`a`} target={`_blank`} href={url}>
                {listen}
              </Button>
            )}
            {video ? (
              <Button size={`medium`} theme={`primary`} colour={`dark`} title={"Watch Video"} as={null} onClick={() => handlePlay(video)}>
                Watch Video
              </Button>
            ) : null}
          </Actions>
        </CTABar>
      ) : null}
    </>
  )
)

export interface Props {
  podcast: {
    additionalContent?: any
    content?: any
    date?: string
    host?: any
    image?: any
    sponsor?: string
    location?: string
    url?: string
    embed?: any
    attributes?: any
    slug?: any
    title?: string
    type?: string
  }
  podcasts?: Array<any>
  video?: any
  template?: any
  handlePlay?: (video: any) => void
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
  isLoggedIn?: boolean
}
