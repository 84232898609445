import React, { useEffect, useState, useCallback, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { useTemplate } from "../../hooks/useTemplate"
import { useSanityPodcast } from "../../hooks/useSanity"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { useExistsInSavedItems, useToggleSavedItemsProduct } from "../../hooks/useSavedItems"

export const withPodcastDetails = Component =>
  React.memo(({ name = "PodcastDetails", page, podcast, path, ...props }) => {
    const [isBrowser, setBrowser] = useState(false)
    const videos = undefined

    useEffect(() => {
      setBrowser(true)
    }, [])
 
    useEffect(() => {
      getRelatedPodcast(podcast, 8, path)
    }, [podcast])

    const {
      helpers: { sanityContent },
    } = useCore()
    const { setActiveVideo, customer } = useApp()

    const { podcast: template } = useTemplate()

    const { getRelatedPodcast, related } = useSanityPodcast()
    const { trackEvent } = useKlaviyo()

    const title = page?.title
    const content = sanityContent(page?.content)

    const handlePlay = useCallback(video => setActiveVideo(video), [setActiveVideo])

    trackEvent("Viewed Podcast", podcast)

    const { addToSavedItems, deleteFromSavedItems } = useToggleSavedItemsProduct()

    const { existsInSavedItems } = useExistsInSavedItems()
    const isExistsInSavedItems = existsInSavedItems(podcast?.id)

    const handleSavedItem = e => {
      e.preventDefault()
      if (isExistsInSavedItems) {
        deleteFromSavedItems(podcast?.id)
      } else {
        addToSavedItems({
          id: podcast?.id,
          content: podcast?.summary,
          image: {
            src: podcast?.image.asset.url,
          },
          title: podcast?.title,
          type: "podcast",
          link: `/podcasts/${podcast?.slug.current}`,
        })
      }
    }

    const isLoggedIn = customer != null

    Component.displayName = name
    return useMemo(
      () =>
        isBrowser && (
          <Component
            {...props}
            {...page}
            title={title}
            content={content}
            template={template}
            podcast={podcast}
            podcasts={related}
            video={podcast?.video}
            handlePlay={handlePlay}
            handleSavedItem={handleSavedItem}
            existsInSavedItems={isExistsInSavedItems}
            isLoggedIn={isLoggedIn}
          />
        ),
      [related, isExistsInSavedItems]
    )
  })
