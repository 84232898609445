import React from "react"
import { graphql } from "gatsby"

import { PodcastDetails as Template } from "../components/Podcast/PodcastDetails"

export const query = graphql`
  query($id: String!) {
    podcast: sanityPodcast(_id: { eq: $id }) {
      ...GatsbyPodcastFragment
    }
    # videos: allSanityVideo {
    #   edges {
    #     node {
    #       ...GatsbyVideoFragment
    #     }
    #   }
    # }
    # template: sanityTemplatePodcast {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
  }
`

export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
